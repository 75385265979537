<template>
  <AppHeader
    :social-items="socialItems"
    :items="navItems"
    :phone-to="phoneTo"
    :lite="isLite"
  >
    <template #logo>
      <img
        src="@/assets/images/Logo.svg?url"
        width="124"
        height="42"
        alt="логотип"
        data-test="header_link_main_page"
      />
    </template>
  </AppHeader>

  <div class="grow">
    <Crumbs v-if="!(isHome || isLite)" class="container" />
    <NuxtPage />
  </div>

  <AppFooter
    class-title="gradient-text"
    class-button="button-hover-gradient"
    :items="isLite ? [] : footerItems"
    :social-items="socialItems"
    :class="{ 'hidden lg:block': isLite }"
    :hide-subscribe="isLite"
    :phone-to="phoneTo"
  >
    <template #logo>
      <img
        src="@/assets/images/Logo.svg?url"
        width="201"
        height="70"
        alt="логотип"
        data-test="footer_link_main_page"
      />
    </template>
  </AppFooter>
  <CookieAlert v-if="route.name !== 'rules-cookie'" class="" />
  <Tabbar :hide-compare="true" :class="{ hidden: isLite }" />
  <ClientOnly>
    <DialogWrapper />
  </ClientOnly>
</template>

<script setup lang="ts">
import {
  header as navItems,
  footer as footerItems,
  social as socialItems,
  contacts,
} from '@/constants/navigation'

await useInitApp()

const route = useRoute()
usePageHead()

const isHome = computed(() => route.name === 'index')
const isLite = computed(() =>
  ['cart', 'checkout', 'voucher-id-activate'].includes(String(route.name)),
)
const { phoneTo } = contacts
</script>
