/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SeoMetadata
 */
export interface SeoMetadata {
    /**
     * 
     * @type {number}
     * @memberof SeoMetadata
     */
    readonly id: number;
    /**
     * Например, "/catalog/"
     * @type {string}
     * @memberof SeoMetadata
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof SeoMetadata
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SeoMetadata
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SeoMetadata
     */
    heading?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SeoMetadata
     */
    image?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SeoMetadata
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof SeoMetadata
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SeoMetadata
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SeoMetadata
     */
    isIndexed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SeoMetadata
     */
    site: number;
}

/**
 * Check if a given object implements the SeoMetadata interface.
 */
export function instanceOfSeoMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "site" in value;

    return isInstance;
}

export function SeoMetadataFromJSON(json: any): SeoMetadata {
    return SeoMetadataFromJSONTyped(json, false);
}

export function SeoMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeoMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'url': json['url'],
        'title': json['title'],
        'description': json['description'],
        'heading': !exists(json, 'heading') ? undefined : json['heading'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'created': (new Date(json['created'])),
        'updatedAt': (new Date(json['updated_at'])),
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'isIndexed': !exists(json, 'is_indexed') ? undefined : json['is_indexed'],
        'site': json['site'],
    };
}

export function SeoMetadataToJSON(value?: SeoMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'title': value.title,
        'description': value.description,
        'heading': value.heading,
        'image': value.image,
        'is_active': value.isActive,
        'is_indexed': value.isIndexed,
        'site': value.site,
    };
}

