<template>
  <footer v-bind="$attrs" class="pb-[66px] lg:pb-0">
    <ClientOnly>
      <div
        v-if="!hideSubscribe && !profileStore.subscribed"
        class="subscribe-item lg:bg-primary-50 relative px-[16px] lg:p-0"
      >
        <div
          class="bg-primary-50 flex flex-col items-stretch rounded-xl lg:flex-row lg:gap-8 lg:rounded-none lg:bg-none"
        >
          <div class="container">
            <div class="relative z-[1] py-6 lg:max-w-[412px] lg:py-11">
              <div class="mb-[20px] text-3xl font-medium" :class="[classTitle]">
                {{ t('components.footer.subscription.title') }}
              </div>
              <p
                class="text-3 text-secondary mb-[30px]"
                :class="(wellness || isb) && '!text-xl'"
              >
                {{ t('components.footer.subscription.content') }}
              </p>
              <transition
                enter-active-class="transition-opacity duration-500"
                enter-from-class="opacity-0"
                leave-active-class="transition-opacity duration-500"
                leave-to-class="opacity-0"
              >
                <form
                  v-if="showForm"
                  class="relative flex flex-col"
                  @submit="handlerSubscribe"
                >
                  <input
                    v-model="formSubscribe.email"
                    type="email"
                    class="input input_lg grow border-[1px] border-gray-100 bg-white placeholder:font-medium placeholder:text-[#9BA7C6]"
                    placeholder="Ваш e-mail"
                    :class="{
                      'border-red-800':
                        validatedData.message || validatedData.detailData,
                    }"
                  />
                  <div
                    v-if="
                      validatedData.detailData && validatedData.detailData.email
                    "
                    class="text-danger mt-2"
                  >
                    {{ validatedData.detailData.email.join(' ') }}
                  </div>
                  <div
                    v-else-if="validatedData.message"
                    class="text-danger mt-2"
                  >
                    {{ validatedData.message }}
                  </div>

                  <div
                    class="mt-[16px] block items-center lg:mt-[26px] lg:flex"
                  >
                    <div
                      class="mb-[16px] mr-[23px] flex w-full items-center lg:mb-0 lg:w-auto"
                    >
                      <label class="mr-[10px] flex w-full items-center">
                        <input
                          v-model="isAgreeDataProcessing"
                          type="checkbox"
                          class="text-primary mr-[10px] h-[30px] w-[30px] rounded-lg"
                          :class="isb && '!text-[#E3B87D]'"
                        />
                        <span
                          class="text-blue-gray-500 w-full text-[12px] font-normal lg:text-[10px]"
                        >
                          <!-- <br class="hidden lg:block" /> -->
                          Нажимая «Подписаться», я даю согласие на получение
                          рекламной рассылки и
                          <a
                            target="_blank"
                            href="/advert.pdf"
                            class="underline underline-offset-[2.5px]"
                          >
                            обработку персональных данных
                          </a>
                        </span>
                      </label>
                    </div>
                    <Btn
                      type="submit"
                      lg
                      primary
                      :disabled="!isAgreeDataProcessing"
                      class="w-full px-[38px] py-[16px] lg:w-auto"
                      :class="[classButton]"
                    >
                      Подписаться
                    </Btn>
                  </div>
                </form>
              </transition>
              <transition
                enter-active-class="transition-opacity duration-500"
                enter-from-class="opacity-0"
                leave-active-class="transition-opacity duration-500"
                leave-to-class="opacity-0"
              >
                <div
                  v-if="!showForm"
                  class="relative flex flex-col justify-center gap-6 lg:grow lg:flex-row lg:gap-8"
                >
                  <div class="text-heading-4 mb-2!! font-semibold">
                    Спасибо за подписку!
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div>
            <slot name="subscription-img">
              <nuxt-picture
                src="/_assets/images/woman-with-cat-for-subscription.png"
                width="808"
                height="392"
                class="lg:absolute lg:inset-y-0 lg:left-1/2 lg:right-auto"
                sizes="xs:100vw sm:100vw md:100vw lg:50vw"
                :img-attrs="{
                  alt: 'Проявить заботу вместе с Monge',
                  title: 'Проявить заботу вместе с Monge',
                  class: 'h-full object-cover object-left',
                }"
              />
            </slot>
          </div>
        </div>
      </div>
    </ClientOnly>
    <div
      :class="[
        dark
          ? ' text-white'
          : wellness
            ? 'bg-[#3E3E5D] text-white'
            : mnyams
              ? 'text-general'
              : 'text-secondary',
      ]"
    >
      <div v-if="fiory">
        <slot name="footer-links" />
      </div>
      <div
        class="container flex flex-col justify-between gap-6 py-6 lg:flex-row lg:gap-10 lg:py-11 xl:gap-20"
        :class="[
          fedor && 'xl:items-center xl:!gap-12',
          isb && 'xl:!gap-13',
          craftia && 'xl:!gap-16',
          fiory && '!py-0',
        ]"
      >
        <div
          class="order-last mx-auto flex flex-col-reverse lg:order-none lg:mx-0 lg:flex-col"
          :class="[
            kranch || isb ? 'justify-start' : 'justify-center',
            (award || craftia) && 'hidden md:block',
            (fiory || panda) && 'hidden',
          ]"
        >
          <div v-if="fedor" class="order-last mb-5 text-center lg:hidden">
            <NuxtLink
              :to="`tel:${phoneTo}`"
              class="block text-xl font-semibold"
            >
              {{ phoneTo }}
            </NuxtLink>
            <p class="text-sm">ежедневно 9.00-21.00</p>
          </div>
          <div>
            <NuxtLink
              to="/"
              class="mb-6 block md:mb-4"
              aria-label="Главная страница"
            >
              <slot name="logo" />
            </NuxtLink>
            <div
              v-if="!fedor && !award && !craftia"
              class="app-footer_social flex justify-center"
              :class="isb && 'lg:justify-start'"
            >
              <div
                v-for="social in socialItems"
                :key="social.href"
                class="mx-1"
              >
                <a
                  v-if="!kranch && !mnyams"
                  :href="social.href"
                  :aria-label="social.icon"
                  target="_blank"
                  class="text-primary hover:text-primary-hover"
                  :class="isb && '!text-[#E3B87D]'"
                  :data-test="'footer_' + social.dataTest"
                >
                  <Icon
                    :name="social.icon"
                    :class="kranch ? 'h-5 w-5' : 'h-7 w-7 lg:h-5 lg:w-5'"
                  />
                </a>
                <a
                  v-else
                  :href="social.href"
                  :aria-label="social.icon"
                  target="_blank"
                  :data-test="'footer_' + social.dataTest"
                  class="hover:text-primary-hover text-primary flex h-8 w-8 items-center justify-center rounded-full bg-[#EFEFEF]"
                >
                  <Icon
                    :name="`${social.icon}-alt` as IconName"
                    class="h-3 w-3"
                  />
                </a>
              </div>
            </div>
          </div>

          <div
            v-if="fedor || isb"
            class="order-first space-y-8 lg:order-none lg:space-y-4"
          >
            <div v-if="!fedor" class="mt-6 space-y-4">
              <Btn
                lg
                secondary
                class="bg-primary-50 hover:bg-primary-hover w-full text-black hover:text-white"
                data-test="footer_contact_us"
                @click="isOpenModal = !isOpenModal"
              >
                Связаться с нами
              </Btn>
              <div
                class="text-center text-lg !text-black lg:text-left"
                :class="!isb && '!font-bold'"
              >
                <NuxtLink
                  :to="`tel:${phoneTo}`"
                  class=""
                  data-test="footer_contact_phone"
                >
                  {{ phoneTo }}
                </NuxtLink>
                <p class="text-xs font-normal" data-test="footer_work_schedule">
                  Ежедневно 9:00 – 21:00 Мск
                </p>
                <NuxtLink
                  :to="`mailto:${mailTo}`"
                  class="mt-3 block"
                  data-test="footer_email"
                >
                  {{ mailTo }}
                </NuxtLink>
              </div>
            </div>
            <slot name="socials" />
          </div>
        </div>

        <div
          v-if="!craftia"
          class="flex w-full flex-col gap-y-6 lg:hidden"
          :class="panda && '!gap-y-2.5'"
        >
          <HeadlessDisclosure
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            v-slot="{ open }"
            as="div"
            :class="panda && 'rounded-2xl bg-[#F5F5F5] p-3 text-black'"
          >
            <HeadlessDisclosureButton
              :class="[wellness || dark ? 'text-white' : 'text-general']"
              class="text-heading-4 flex h-[30px] w-full cursor-pointer items-center justify-between gap-x-2 font-semibold leading-6 transition"
            >
              <span>{{ item.title }}</span>
              <Icon
                name="expand-down"
                :class="[
                  'h-6 w-6 shrink-0 transition duration-200',
                  open && 'rotate-180',
                ]"
              />
            </HeadlessDisclosureButton>
            <HeadlessDisclosurePanel
              as="ul"
              class="text-3 mt-3.5 space-y-3.5"
              :class="
                fiory &&
                'grid-flow-col grid-cols-2 grid-rows-3 gap-3 sm:grid sm:grid-rows-[25px_25px_25px] sm:space-y-0 lg:block'
              "
            >
              <li
                v-for="(child, i) in item.children"
                :key="i"
                :class="award && 'opacity-60'"
              >
                <NuxtLink
                  v-if="child.type !== 'file'"
                  :to="child.href"
                  :data-test="child.dataTest"
                  class="hover:text-primary-hover transition-colors"
                >
                  <span v-html="child.title" />
                </NuxtLink>
                <a
                  v-else
                  :href="child.href"
                  target="_blank"
                  :data-test="child.dataTest"
                  class="hover:text-primary-hover transition-colors"
                  v-html="child.title"
                ></a>
              </li>
            </HeadlessDisclosurePanel>
          </HeadlessDisclosure>
        </div>

        <!-- columns and links from json -->
        <div
          v-if="items.length"
          class="app-footer_items hidden grow gap-11 lg:flex lg:gap-10 xl:gap-20"
          :class="[
            isb && 'xl:!gap-2',
            (fedor || craftia) && 'xl:!gap-12',
            fiory && '!hidden',
          ]"
        >
          <div
            v-for="item in items"
            :key="item.title"
            class="basis-[150px]"
            data-test="footer"
            :class="[
              { 'basis-[250px]': kranch || mnyams || isb || craftia },
              { 'basis-[180px]': wellness },
            ]"
          >
            <div
              class="text-heading-4 whitespace-nowrap font-semibold"
              :class="[
                wellness || dark ? 'text-white' : 'text-general',
                { uppercase: award },
                panda && '!text-[32px] !text-black',
              ]"
            >
              {{ item.title }}
            </div>
            <ul
              class="mt-6 space-y-3.5"
              :class="panda && '!text-txt space-y-6'"
            >
              <li
                v-for="(child, i) in item.children"
                :key="i"
                class="leading-none"
              >
                <NuxtLink
                  v-if="child.type !== 'file'"
                  :to="child.href"
                  :data-test="child.dataTest"
                  class="hover:text-primary-hover transition-colors"
                >
                  {{ child.title }}
                </NuxtLink>
                <a
                  v-else
                  :href="child.href"
                  target="_blank"
                  class="hover:text-primary-hover transition-colors"
                  :data-test="child.dataTest"
                  >{{ child.title }}</a
                >
                <div
                  v-if="child.text"
                  class="text-xs"
                  :data-test="fedor && 'footer_work_schedule'"
                >
                  {{ child.text }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- /columns and links from json -->
        <div v-if="fedor">
          <Btn
            lg
            secondary
            data-test="footer_contact_us"
            class="hover:text-primary-hover w-full bg-[#427628] text-white md:h-[62px] lg:mb-5 xl:min-w-[233px] xl:max-w-[263px]"
            @click="isOpenModal = !isOpenModal"
          >
            Связаться с нами
          </Btn>
        </div>

        <div
          v-else-if="!award && !craftia && !isb && !fiory"
          class="grid content-start gap-x-8 gap-y-4 text-center"
          :class="[
            kranch && 'min-w-[223px]',
            fiory && '!hidden',
            items.length ? 'grid-cols-1' : 'grid-cols-2',
          ]"
        >
          <div
            class="flex text-center text-lg font-bold lg:text-left"
            :class="[
              wellness || kranch ? '!text-white' : '!text-black',
              items.length ? 'flex-col' : 'flex-col-reverse',
            ]"
          >
            <NuxtLink
              data-test="footer_contact_phone"
              :to="`tel:${phoneTo}`"
              :class="[panda && 'mt-3 block text-[32px] font-semibold md:mt-0']"
            >
              {{ phoneTo }}
            </NuxtLink>
            <p data-test="footer_work_schedule" class="text-xs font-normal">
              Ежедневно 9:00 – 21:00 Мск
            </p>
          </div>
          <Btn
            lg
            secondary
            :data-test="!fiory && 'footer_contact_us'"
            :class="[
              kranch
                ? 'hover:bg-primary-hover bg-[#585858] text-white hover:text-white'
                : wellness
                  ? 'bg-[#585881] text-white'
                  : mnyams
                    ? 'bg-accent-secondary rounded-[40px] px-10'
                    : 'bg-primary-50 text-primary',
            ]"
            class="hover:text-primary-hover w-full"
            @click="isOpenModal = !isOpenModal"
          >
            Связаться с нами
          </Btn>
        </div>
        <div
          v-if="award"
          class="flex flex-col items-center gap-3 md:items-start"
        >
          <NuxtLink
            to="/"
            class="block md:mb-4 md:hidden"
            aria-label="Главная страница"
          >
            <slot name="logo" />
          </NuxtLink>
          <p
            class="text-md md:text-sm md:opacity-60"
            data-test="footer_work_schedule"
          >
            Ежедневно 9:00 – 21:00 Мск
          </p>
          <div>
            <NuxtLink
              data-test="footer_contact_phone"
              :to="`tel:${phoneTo}`"
              class="block text-lg font-medium lg:text-left"
            >
              {{ phoneTo }}
            </NuxtLink>
            <p class="text-md md:text-sm md:opacity-60">
              Бесплатная горячая линия
            </p>
          </div>
          <NuxtLink
            :to="`mailto:${mailTo}`"
            data-test="footer_email"
            class="block text-lg font-medium lg:text-left"
          >
            {{ mailTo }}
          </NuxtLink>
          <Btn
            data-test="footer_contact_us"
            class="hover:text-primary-hover text-md uppercase underline underline-offset-2 transition-colors"
            @click="isOpenModal = !isOpenModal"
          >
            Связаться с нами
          </Btn>
          <SocialLinks
            dark
            :items="socialItems"
            class="my-4 !justify-start !text-white"
          />
        </div>
        <div
          v-if="craftia"
          class="mt-4 flex min-w-[150px] flex-col items-center gap-3 lg:items-start"
        >
          <NuxtLink
            to="/"
            class="block md:mb-4 md:hidden"
            aria-label="Главная страница"
          >
            <slot name="logo" />
          </NuxtLink>
          <div class="grid w-full grid-cols-2 lg:hidden">
            <ul
              v-for="(item, itemIndex) in items"
              :key="itemIndex"
              :class="
                item?.orderFirst &&
                'order-first col-span-2 mb-3 grid grid-cols-2 border-b pb-3'
              "
            >
              <li v-for="(child, i) in item.children" :key="i" class="my-2">
                <NuxtLink
                  v-if="child.type !== 'file'"
                  :to="child.href"
                  class="hover:text-primary-hover transition-colors"
                >
                  {{ child.title }}
                </NuxtLink>
                <a
                  v-else
                  :href="child.href"
                  target="_blank"
                  class="hover:text-primary-hover transition-colors"
                  >{{ child.title }}</a
                >
              </li>
            </ul>
          </div>
          <Btn
            lg
            data-test="footer_contact_us"
            secondary
            class="hover:text-primary-hover btn_border font-myriad mb-4 mt-10 w-full !border-white font-medium text-white lg:hidden lg:max-w-[177px]"
            @click="isOpenModal = !isOpenModal"
          >
            Связаться с нами
          </Btn>
          <NuxtLink
            data-test="footer_contact_phone"
            :to="`tel:${phoneTo}`"
            class="text-2xl font-semibold md:text-lg"
          >
            {{ phoneTo }}
            <p
              v-if="!craftia"
              data-test="footer_contact_phone"
              class="text-center text-xs font-normal opacity-70 md:text-left"
            >
              звонок бесплатный
            </p>
            <p
              v-else
              data-test="footer_work_schedule"
              class="text-center text-xs font-normal opacity-70 lg:text-left"
            >
              Ежедневно 9:00 – 21:00 Мск
            </p>
          </NuxtLink>
          <NuxtLink
            data-test="footer_email"
            :to="`mailto:${mailTo}`"
            class="block text-lg font-semibold lg:text-left"
          >
            {{ mailTo }}
          </NuxtLink>
          <SocialLinks
            dark
            :items="socialItems"
            class="my-1 !justify-start text-2xl !text-white"
            icon-theme="md:h-8 md:w-8"
          />
        </div>
        <Btn
          v-if="craftia"
          lg
          secondary
          data-test="footer_contact_us"
          class="hover:text-primary-hover btn_border font-myriad mb-4 mt-6 hidden w-full !border-white bg-none font-medium text-white md:max-w-[177px] lg:block"
          @click="isOpenModal = !isOpenModal"
        >
          Связаться с нами
        </Btn>
      </div>
      <div
        v-if="fiory"
        class="bg-primary flex min-h-[450px] items-end py-10 md:min-h-[330px] md:pb-4 lg:min-h-[350px]"
      >
        <div class="container pb-12">
          <div
            class="flex flex-col flex-wrap items-start justify-between gap-6 md:flex-row"
          >
            <div class="group">
              Хочу позвонить!<br />
              <NuxtLink
                :to="`tel:${phoneTo}`"
                data-test="footer_contact_phone"
                class="group-hover:text-accent text-xl font-semibold lg:text-2xl"
              >
                {{ phoneTo }}
              </NuxtLink>
            </div>
            <div>
              Мы работаем:
              <p
                class="text-xl font-semibold lg:text-2xl"
                data-test="footer_work_schedule"
              >
                Ежедневно 9:00 - 21:00 Мск
              </p>
            </div>
            <div class="group">
              Связаться с нами:
              <NuxtLink
                data-test="footer_email"
                :to="`mailto:${mailTo}`"
                class="group-hover:text-accent block text-xl font-semibold lg:text-left lg:text-2xl"
              >
                {{ mailTo }}
              </NuxtLink>
            </div>
            <div
              class="flex items-center gap-3"
              :class="fiory && 'w-full md:w-auto lg:gap-[30px]'"
            >
              <Btn
                lg
                data-test="footer_contact_us"
                secondary
                class="hover:ring-accent w-full max-w-[259px] shrink !border-white font-medium text-white ring-1 ring-white hover:text-white hover:opacity-50 hover:ring-white"
                @click="isOpenModal = !isOpenModal"
              >
                Связаться с нами
              </Btn>
              <SocialLinks
                :items="socialItems"
                icon-theme="h-6 w-6 md:h-8 md:w-8"
                social-theme="ring-1 ring-white rounded-full p-3 hover:opacity-50 hover:text-white"
                dark
                class="!justify-start"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="container flex w-full flex-col items-center justify-center gap-x-6 gap-y-2 pb-10 opacity-60 md:justify-start lg:flex-row"
        :class="[craftia && 'md:justify-center', panda && '!text-txt']"
      >
        <p
          v-for="(item, index) in docList"
          :key="index"
          class="text-center text-xs lg:text-left"
        >
          <NuxtLink
            :to="item.href"
            target="_blank"
            class="underline underline-offset-4 transition-colors hover:opacity-60"
            :data-test="item.dataTest"
          >
            {{ item.title }}
          </NuxtLink>
        </p>
        <p
          class="text-center text-sm lg:text-left"
          data-test="footer_trademark"
        >
          © ТМ {{ t('app.name') }}, все права защищены
        </p>
      </div>
    </div>
  </footer>
  <FeedbackDialog v-model="isOpenModal" />
</template>

<script setup lang="ts">
import { type Status, StatusFromJSON } from '@/openapi_fetch'

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  mailTo: {
    type: String,
    default: 'hotline@valta.ru',
  },
  phoneTo: {
    type: String,
    default: '8 (800) 600-28-04',
  },
  socialItems: Array,
  classTitle: {
    type: String,
    default: '',
  },
  classButton: {
    type: String,
    default: '',
  },
  wellness: Boolean,
  isb: Boolean,
  kranch: Boolean,
  dark: Boolean,
  fedor: Boolean,
  award: Boolean,
  mnyams: Boolean,
  panda: Boolean,
  craftia: Boolean,
  fiory: Boolean,
  hideSubscribe: Boolean,
})

const { loggedIn } = useAuth()
const profileStore = useProfileStore()
const { SubscribersApi } = useOpenApi()
const { t } = useI18n()
const { send: sendApimonster } = useApimonster()

const formSubscribe = reactive({
  email: '',
})
const validatedData = ref<Status>({
  message: '',
  detailData: null,
})
const errorMessage = ref('')
const showForm = ref(true)
const isAgreeDataProcessing = ref(true)
const isOpenModal = ref(false)

const docList = [
  {
    title: 'Правила использования cookie',
    href: '/rules/cookie/',
    dataTest: 'footer_cookie',
  },
  {
    title: 'Правила применения рекомендательных технологий',
    href: '/rules/recomendation/',
    dataTest: 'footer_recomendation',
  },
  {
    title: 'Политика обработки персональных данных',
    href: '/rules/policy/',
    dataTest: 'footer_policy',
  },
]
async function handlerSubscribe(e: Event) {
  e.preventDefault()
  errorMessage.value = ''
  try {
    await SubscribersApi.subscribersCreate({
      subscriberRequest: {
        email: formSubscribe.email,
      },
    })

    await sendApimonster({
      email: formSubscribe.email,
    })

    validatedData.value = {
      message: '',
      detailData: null,
    }
    showForm.value = false
    formSubscribe.email = ''
    setTimeout(() => {
      showForm.value = true
    }, 3000)
  } catch (error: any) {
    validatedData.value = useApiError(error, StatusFromJSON) as Status
  } finally {
    if (loggedIn.value) {
      setTimeout(async () => {
        await profileStore.getAccountInfo()
      }, 3000)
    }
  }
}
</script>
