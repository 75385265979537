import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { SENTRY_DSN, SENTRY_ENVIRONMENT, APP_NAME, APP_VERSION } =
    useRuntimeConfig().public

  if (SENTRY_DSN && SENTRY_ENVIRONMENT) {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      release:
        APP_NAME && APP_VERSION ? `${APP_NAME}@${APP_VERSION}` : undefined,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      logErrors: true,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  }
})
