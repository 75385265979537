/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestDeliveryCityAreaCreate,
  BadRequestDeliveryInfo,
  BadRequestDeliveryPointsCreate,
  BlockedDeliveryDates,
  CdekCity,
  DaData,
  DeliveryCityArea,
  DeliveryCityAreaRequest,
  DeliveryDate,
  DeliveryInfo,
  DeliveryPointsCreateRequest,
  DeliveryTimeInterval,
  GeoLocateAddress,
  GeoSuggestionsAddressRequestRequest,
  GetDeliveryAddressInfo,
  GetLastOrderDeliveryAddressInfo,
  GetPickUpAddressInfo,
  PageNotFound,
  PatchedDeliveryCityAreaRequest,
  SetDeliveryInfoRequest,
  Status,
} from '../models';
import {
    BadRequestDeliveryCityAreaCreateFromJSON,
    BadRequestDeliveryCityAreaCreateToJSON,
    BadRequestDeliveryInfoFromJSON,
    BadRequestDeliveryInfoToJSON,
    BadRequestDeliveryPointsCreateFromJSON,
    BadRequestDeliveryPointsCreateToJSON,
    BlockedDeliveryDatesFromJSON,
    BlockedDeliveryDatesToJSON,
    CdekCityFromJSON,
    CdekCityToJSON,
    DaDataFromJSON,
    DaDataToJSON,
    DeliveryCityAreaFromJSON,
    DeliveryCityAreaToJSON,
    DeliveryCityAreaRequestFromJSON,
    DeliveryCityAreaRequestToJSON,
    DeliveryDateFromJSON,
    DeliveryDateToJSON,
    DeliveryInfoFromJSON,
    DeliveryInfoToJSON,
    DeliveryPointsCreateRequestFromJSON,
    DeliveryPointsCreateRequestToJSON,
    DeliveryTimeIntervalFromJSON,
    DeliveryTimeIntervalToJSON,
    GeoLocateAddressFromJSON,
    GeoLocateAddressToJSON,
    GeoSuggestionsAddressRequestRequestFromJSON,
    GeoSuggestionsAddressRequestRequestToJSON,
    GetDeliveryAddressInfoFromJSON,
    GetDeliveryAddressInfoToJSON,
    GetLastOrderDeliveryAddressInfoFromJSON,
    GetLastOrderDeliveryAddressInfoToJSON,
    GetPickUpAddressInfoFromJSON,
    GetPickUpAddressInfoToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PatchedDeliveryCityAreaRequestFromJSON,
    PatchedDeliveryCityAreaRequestToJSON,
    SetDeliveryInfoRequestFromJSON,
    SetDeliveryInfoRequestToJSON,
    StatusFromJSON,
    StatusToJSON,
} from '../models';

export interface DeliveryDeliverypointsCreateRequest {
    deliveryPointsCreateRequest?: DeliveryPointsCreateRequest;
}

export interface DeliveryGeolocateSuggestionsAddressCreateRequest {
    geoSuggestionsAddressRequestRequest: GeoSuggestionsAddressRequestRequest;
}

export interface DeliveryListAreaCreateRequest {
    deliveryCityAreaRequest?: DeliveryCityAreaRequest;
}

export interface DeliveryListAreaDestroyRequest {
    id: number;
}

export interface DeliveryListAreaListRequest {
    beltwayDistance?: number;
    beltwayHit?: string;
    name?: string;
    region?: number;
}

export interface DeliveryListAreaPartialUpdateRequest {
    id: number;
    patchedDeliveryCityAreaRequest?: PatchedDeliveryCityAreaRequest;
}

export interface DeliveryListAreaRetrieveRequest {
    id: number;
}

export interface DeliveryListAreaUpdateRequest {
    id: number;
    deliveryCityAreaRequest?: DeliveryCityAreaRequest;
}

export interface DeliverySetDeliveryCreateRequest {
    setDeliveryInfoRequest: SetDeliveryInfoRequest;
}

export interface DeliverySuggestionsAddressListRequest {
    query?: string;
}

export interface DeliveryTimeIntervalRetrieveRequest {
    beltwayDistance?: number;
    beltwayHit?: string;
    date?: string;
    region?: string;
}

/**
 * 
 */
export class DeliveryApi extends runtime.BaseAPI {

    /**
     * Вернет будущие заблокированные даты доставки (объекты у которых дата конца блокировки больше текущей даты).
     * Запрос на получение будущих заблокированных дат доставки.
     */
    async deliveryBlockedDeliveryDatesRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlockedDeliveryDates>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/blocked-delivery-dates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlockedDeliveryDatesFromJSON(jsonValue));
    }

    /**
     * Вернет будущие заблокированные даты доставки (объекты у которых дата конца блокировки больше текущей даты).
     * Запрос на получение будущих заблокированных дат доставки.
     */
    async deliveryBlockedDeliveryDatesRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlockedDeliveryDates> {
          try{
        const response = await this.deliveryBlockedDeliveryDatesRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение информации об адресе доставки.
     */
    async deliveryDeliveryAddressRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDeliveryAddressInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/delivery-address/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDeliveryAddressInfoFromJSON(jsonValue));
    }

    /**
     * Запрос на получение информации об адресе доставки.
     */
    async deliveryDeliveryAddressRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDeliveryAddressInfo> {
          try{
        const response = await this.deliveryDeliveryAddressRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вернет первую актуальную дату доставки после проверки на заблокированные даты доставки. Далее эта дата передается в /time-interval/.
     * Запрос на получение первой актуальной даты доставки.
     */
    async deliveryDeliveryDateRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryDate>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/delivery-date/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryDateFromJSON(jsonValue));
    }

    /**
     * Вернет первую актуальную дату доставки после проверки на заблокированные даты доставки. Далее эта дата передается в /time-interval/.
     * Запрос на получение первой актуальной даты доставки.
     */
    async deliveryDeliveryDateRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryDate> {
          try{
        const response = await this.deliveryDeliveryDateRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на добавление точки доставки.
     */
    async deliveryDeliverypointsCreateRaw(requestParameters: DeliveryDeliverypointsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CdekCity>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/deliverypoints/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliveryPointsCreateRequestToJSON(requestParameters.deliveryPointsCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CdekCityFromJSON));
    }

    /**
     * Запрос на добавление точки доставки.
     */
    async deliveryDeliverypointsCreate(requestParameters: DeliveryDeliverypointsCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CdekCity>> {
          try{
        const response = await this.deliveryDeliverypointsCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Подсказки для выбора адреса DADATA
     */
    async deliveryGeolocateSuggestionsAddressCreateRaw(requestParameters: DeliveryGeolocateSuggestionsAddressCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GeoLocateAddress>>> {
        if (requestParameters.geoSuggestionsAddressRequestRequest === null || requestParameters.geoSuggestionsAddressRequestRequest === undefined) {
            throw new runtime.RequiredError('geoSuggestionsAddressRequestRequest','Required parameter requestParameters.geoSuggestionsAddressRequestRequest was null or undefined when calling deliveryGeolocateSuggestionsAddressCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/geolocate-suggestions-address/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GeoSuggestionsAddressRequestRequestToJSON(requestParameters.geoSuggestionsAddressRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GeoLocateAddressFromJSON));
    }

    /**
     * Подсказки для выбора адреса DADATA
     */
    async deliveryGeolocateSuggestionsAddressCreate(requestParameters: DeliveryGeolocateSuggestionsAddressCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GeoLocateAddress>> {
          try{
        const response = await this.deliveryGeolocateSuggestionsAddressCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение информации о доставке из последнего заказа
     */
    async deliveryLastOrderAddressRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastOrderDeliveryAddressInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/last-order-address/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastOrderDeliveryAddressInfoFromJSON(jsonValue));
    }

    /**
     * Запрос на получение информации о доставке из последнего заказа
     */
    async deliveryLastOrderAddressRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastOrderDeliveryAddressInfo> {
          try{
        const response = await this.deliveryLastOrderAddressRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async deliveryListAreaCreateRaw(requestParameters: DeliveryListAreaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryCityArea>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/list-area/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliveryCityAreaRequestToJSON(requestParameters.deliveryCityAreaRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryCityAreaFromJSON(jsonValue));
    }

    /**
     */
    async deliveryListAreaCreate(requestParameters: DeliveryListAreaCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryCityArea> {
          try{
        const response = await this.deliveryListAreaCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async deliveryListAreaDestroyRaw(requestParameters: DeliveryListAreaDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deliveryListAreaDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/list-area/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deliveryListAreaDestroy(requestParameters: DeliveryListAreaDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deliveryListAreaDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Запрос на получения зон доставки.
     */
    async deliveryListAreaListRaw(requestParameters: DeliveryListAreaListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeliveryCityArea>>> {
        const queryParameters: any = {};

        if (requestParameters.beltwayDistance !== undefined) {
            queryParameters['beltway_distance'] = requestParameters.beltwayDistance;
        }

        if (requestParameters.beltwayHit !== undefined) {
            queryParameters['beltway_hit'] = requestParameters.beltwayHit;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.region !== undefined) {
            queryParameters['region'] = requestParameters.region;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/list-area/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliveryCityAreaFromJSON));
    }

    /**
     * Запрос на получения зон доставки.
     */
    async deliveryListAreaList(requestParameters: DeliveryListAreaListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeliveryCityArea>> {
          try{
        const response = await this.deliveryListAreaListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async deliveryListAreaPartialUpdateRaw(requestParameters: DeliveryListAreaPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryCityArea>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deliveryListAreaPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/list-area/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDeliveryCityAreaRequestToJSON(requestParameters.patchedDeliveryCityAreaRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryCityAreaFromJSON(jsonValue));
    }

    /**
     */
    async deliveryListAreaPartialUpdate(requestParameters: DeliveryListAreaPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryCityArea> {
          try{
        const response = await this.deliveryListAreaPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение экземпляра сущности (стоимость зоны доставки).
     */
    async deliveryListAreaRetrieveRaw(requestParameters: DeliveryListAreaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryCityArea>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deliveryListAreaRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/list-area/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryCityAreaFromJSON(jsonValue));
    }

    /**
     * Запрос на получение экземпляра сущности (стоимость зоны доставки).
     */
    async deliveryListAreaRetrieve(requestParameters: DeliveryListAreaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryCityArea> {
          try{
        const response = await this.deliveryListAreaRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async deliveryListAreaUpdateRaw(requestParameters: DeliveryListAreaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryCityArea>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deliveryListAreaUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/list-area/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeliveryCityAreaRequestToJSON(requestParameters.deliveryCityAreaRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryCityAreaFromJSON(jsonValue));
    }

    /**
     */
    async deliveryListAreaUpdate(requestParameters: DeliveryListAreaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryCityArea> {
          try{
        const response = await this.deliveryListAreaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение информации о доставке точки ПВЗ.
     */
    async deliveryPickupAddressRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPickUpAddressInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/pickup-address/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPickUpAddressInfoFromJSON(jsonValue));
    }

    /**
     * Запрос на получение информации о доставке точки ПВЗ.
     */
    async deliveryPickupAddressRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPickUpAddressInfo> {
          try{
        const response = await this.deliveryPickupAddressRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * При GET отдаёт список адресов доставки, адресов пунктов самовывоза и адрес последнего заказа посетителя.
     * Запрос на добавления информации о доставке покупателя.
     */
    async deliverySetDeliveryCreateRaw(requestParameters: DeliverySetDeliveryCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryInfo>> {
        if (requestParameters.setDeliveryInfoRequest === null || requestParameters.setDeliveryInfoRequest === undefined) {
            throw new runtime.RequiredError('setDeliveryInfoRequest','Required parameter requestParameters.setDeliveryInfoRequest was null or undefined when calling deliverySetDeliveryCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/set-delivery/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDeliveryInfoRequestToJSON(requestParameters.setDeliveryInfoRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryInfoFromJSON(jsonValue));
    }

    /**
     * При GET отдаёт список адресов доставки, адресов пунктов самовывоза и адрес последнего заказа посетителя.
     * Запрос на добавления информации о доставке покупателя.
     */
    async deliverySetDeliveryCreate(requestParameters: DeliverySetDeliveryCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryInfo> {
          try{
        const response = await this.deliverySetDeliveryCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Подсказки для выбора адреса DADATA
     */
    async deliverySuggestionsAddressListRaw(requestParameters: DeliverySuggestionsAddressListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DaData>>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/suggestions-address/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DaDataFromJSON));
    }

    /**
     * Подсказки для выбора адреса DADATA
     */
    async deliverySuggestionsAddressList(requestParameters: DeliverySuggestionsAddressListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DaData>> {
          try{
        const response = await this.deliverySuggestionsAddressListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отдаёт интервалы для доставки ориентируясь на регион и дату в query. Так же возможно передать параметры beltway_hit и beltway_distance в query для условий интервалов доставки связанных с кольцевой.
     * Запрос на получение временных периодов.
     */
    async deliveryTimeIntervalRetrieveRaw(requestParameters: DeliveryTimeIntervalRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryTimeInterval>> {
        const queryParameters: any = {};

        if (requestParameters.beltwayDistance !== undefined) {
            queryParameters['beltway_distance'] = requestParameters.beltwayDistance;
        }

        if (requestParameters.beltwayHit !== undefined) {
            queryParameters['beltway_hit'] = requestParameters.beltwayHit;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        if (requestParameters.region !== undefined) {
            queryParameters['region'] = requestParameters.region;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/delivery/time-interval/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryTimeIntervalFromJSON(jsonValue));
    }

    /**
     * Отдаёт интервалы для доставки ориентируясь на регион и дату в query. Так же возможно передать параметры beltway_hit и beltway_distance в query для условий интервалов доставки связанных с кольцевой.
     * Запрос на получение временных периодов.
     */
    async deliveryTimeIntervalRetrieve(requestParameters: DeliveryTimeIntervalRetrieveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryTimeInterval> {
          try{
        const response = await this.deliveryTimeIntervalRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
