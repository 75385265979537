<template>
  <div class="grid lg:grid-cols-2">
    <div class="relative h-[58vh] bg-gray-50 lg:h-[612px]">
      <!-- чтобы карта не инициализировалась при переключении табов -->
      <div
        v-show="formDeliveryType === FormDeliveryTypeEnum.COURIER"
        class="h-full w-full"
      >
        <ClientOnly>
          <YandexMap
            v-if="showMap"
            :coords="cityCoords"
            :settings="ymapSettings"
            :controls="ymapControls"
            :zoom="DEFAULT_ZOOM"
            :show-all-markers="false"
            :use-object-manager="false"
            :init-without-markers="true"
            class="h-full w-full"
            @map-was-initialized="onDeliveryMapInit"
            @boundschange="addressFromMapErrorMessage = ''"
            @click="() => onClickMapHandler()"
          />
        </ClientOnly>

        <div
          v-if="addressFromMapErrorMessage"
          class="shadow-container text-warning absolute left-0 right-14 top-3 z-10 mx-3.5 rounded-2xl bg-white p-3.5 text-sm font-medium"
        >
          {{ addressFromMapErrorMessage }}
        </div>
      </div>

      <ClientOnly v-if="formDeliveryType === FormDeliveryTypeEnum.COURIER">
        <div v-if="isIndividualOrder" class="absolute top-0 mx-3.5 lg:max-w-xs">
          <div
            class="divide-blue-gray-200 shadow-container mt-3 divide-y rounded-2xl bg-white text-sm font-medium"
          >
            <p class="px-4 py-3.5 text-[#dd2e2e]">
              Стоимость доставки рассчитывается индивидуально после согласования
              заказа
            </p>
          </div>
        </div>

        <div
          v-else-if="areasProperties?.length"
          class="absolute top-0 mx-3.5 lg:max-w-xs"
        >
          <Btn
            v-if="isMobile"
            secondary
            class="text-general shadow-container mt-3.5 space-x-1 bg-white"
            @click="isAreasLegendsVisible = !isAreasLegendsVisible"
          >
            <Icon
              v-if="isAreasLegendsVisible"
              name="expand-left"
              class="w-4.5 h-4.5"
            />
            <span>{{
              isAreasLegendsVisible ? 'Скрыть' : 'Зоны доставки'
            }}</span>
          </Btn>

          <transition
            v-if="isMobile"
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="opacity-0 -translate-y-4"
            leave-active-class="transition duration-300 ease-in"
            leave-to-class="opacity-0 -translate-y-4"
          >
            <div
              v-if="isAreasLegendsVisible"
              class="divide-blue-gray-200 shadow-container mt-3 divide-y rounded-2xl bg-white text-sm font-medium"
            >
              <div
                v-for="(item, i) in areasProperties"
                :key="item.id"
                class="px-4 py-3.5"
              >
                <span class="font-semibold" :style="{ color: item.color }">{{
                  item.name || `Зона ${i + 1}`
                }}</span>
                -
                <span>
                  {{
                    `Стоимость доставки ${n(item.price ?? 500)} руб.` +
                    (item.freeAmount
                      ? `, Бесплатная от ${n(item.freeAmount)} руб.`
                      : '')
                  }}
                </span>
              </div>
            </div>
          </transition>
          <div
            v-else
            class="divide-blue-gray-200 shadow-container mt-3 divide-y rounded-2xl bg-white text-sm font-medium"
          >
            <p v-if="isIndividualOrder" class="px-4 py-3.5 text-[#dd2e2e]">
              Стоимость доставки рассчитывается индивидуально после согласования
              заказа
            </p>

            <template v-else>
              <div
                v-for="(item, i) in areasProperties"
                :key="item.id"
                class="px-4 py-3.5"
              >
                <span class="font-semibold" :style="{ color: item.color }">{{
                  item.name || `Зона ${i + 1}`
                }}</span>
                -
                <span>
                  {{
                    `Стоимость доставки ${n(item.price ?? 500)} руб.` +
                    (item.freeAmount
                      ? `, Бесплатная от ${n(item.freeAmount)} руб.`
                      : '')
                  }}
                </span>
              </div>
            </template>
          </div>
        </div>
      </ClientOnly>

      <template v-if="formDeliveryType === FormDeliveryTypeEnum.COURIER">
        <Icon
          v-if="
            deliveryAddressCoords &&
            (deliveryAddressModel?.house || deliveryAddressModel?.stead)
          "
          :title="deliveryAddressModel?.address"
          name="pin-fill"
          class="text-primary absolute left-1/2 top-1/2 h-[58px] w-[58px] -translate-x-1/2 -translate-y-[48px]"
        />
        <button
          v-else
          type="button"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-full text-black hover:text-gray-900"
          @click="setDeliveryAddressFromMap"
        >
          <DeliveryAddressMarkerSvg />
        </button>
      </template>

      <div
        v-if="formDeliveryType === FormDeliveryTypeEnum.PICK_UP"
        class="h-full w-full"
      >
        <ClientOnly>
          <YandexMap
            v-if="showMap"
            v-model:zoom="pickupMapZoom"
            :coords="cityCoords"
            :settings="ymapSettings"
            :controls="ymapControls"
            :options="ymapOptions"
            :show-all-markers="false"
            :use-object-manager="true"
            :init-without-markers="true"
            :cluster-options="{
              1: {
                preset: 'islands#nightClusterIcons',
                clusterize: true,
                gridSize: 128,
              },
            }"
            :map-events="['map-was-initialized', 'balloonopen', 'balloonclose']"
            class="h-full w-full"
            @map-was-initialized="onPickupMapInit"
            @balloonopen="onBalloonOpen"
            @balloonclose="onBalloonClose"
            @boundschange="onPickupMapBoundschange"
          >
            <YmapMarker
              v-for="item in deliveryPoints"
              :key="item.code"
              :marker-id="item.code"
              :coords="[item.latitude, item.longitude]"
              :options="{
                preset:
                  item.code === deliveryPoint?.code
                    ? 'islands#nightCircleDotIcon'
                    : 'islands#nightCircleIcon',
              }"
              :hint-content="item.addressFull"
              :balloon-template="`
                <div class='space-y-1.5'>
                  <div class='mb-2 text-base font-semibold'>${item.name}</div>
                  <div class='text-sm'>${item.addressFull}</div>
                  <div class='pb-2 text-xs text-secondary'>${item.workTime}</div>
                  <button id='${item.code}' class='px-6 btn btn_secondary bg-blue-50'>Выбрать</button>
                </div>`"
              cluster-name="1"
            />
          </YandexMap>
        </ClientOnly>
      </div>
    </div>

    <div class="flex flex-col p-3.5 lg:order-first lg:p-8">
      <HeadlessDialogTitle
        as="div"
        class="text-1 lg:text-heading-2 font-semibold"
      >
        {{ title }}
      </HeadlessDialogTitle>

      <p class="text-2 mt-3.5 hidden font-medium lg:block">
        {{ description }}
      </p>

      <div
        class="round-corner mt-3.5 grid grid-cols-2 gap-x-2 rounded-2xl bg-[#F1F4F8] p-1 lg:mt-6"
      >
        <Btn
          v-for="item in formDeliveryTypeItems"
          :key="item.type"
          :class="[
            'btn h-[50px] rounded-2xl text-base font-semibold',
            formDeliveryType === item.type
              ? 'text-primary hover:text-primary-hover bg-white'
              : 'text-secondary hover:hover:text-blue-gray-700',
          ]"
          @click="formDeliveryType = item.type"
        >
          {{ item.text }}
        </Btn>
      </div>

      <div
        v-if="formDeliveryType === FormDeliveryTypeEnum.COURIER"
        class="mt-6"
      >
        <AddressInput
          ref="deliveryAddressInputRef"
          :model-value="deliveryAddressModel"
          options-max-height="280px"
          @update:model-value="updateDeliveryAddressModel"
        >
          <template #label>
            <HeadlessComboboxLabel
              class="text-4 lg:text-3 mb-2 inline-block align-top leading-[16px] text-gray-700"
            >
              Город, улица, дом
            </HeadlessComboboxLabel>
          </template>
        </AddressInput>

        <div v-if="deliveryAddressList.length" class="mt-6 space-y-3.5">
          <div v-for="item in deliveryAddressList" :key="item.address.id">
            <Btn
              class="text-primary hover:text-primary-hover text-left underline underline-offset-4"
              @click="updateDeliveryAddressModel(item.address)"
            >
              {{ item.address.address }}
            </Btn>
          </div>
        </div>
      </div>
      <div v-else class="mt-6">
        <AddressInput
          ref="pickupAddressInputRef"
          :model-value="pickupAddressModel"
          :check-address-completed="false"
          :dadata-params="{ from_bound: { value: 'city' } }"
          :loading="deliveryPointsLoading"
          options-max-height="280px"
          @update:model-value="updatePickupAddressModel"
        >
          <template #label>
            <HeadlessComboboxLabel
              class="text-4 lg:text-3 mb-2 inline-block align-top leading-[16px] text-gray-700"
            >
              Искать на карте
            </HeadlessComboboxLabel>
          </template>
        </AddressInput>

        <div v-if="pickupAddressList.length" class="mt-6 space-y-3.5">
          <div v-for="item in pickupAddressList" :key="item.address.id">
            <Btn
              class="text-primary hover:text-primary-hover text-left underline underline-offset-4"
              @click="setDeliveryPointFromPickupAddress(item.address)"
            >
              {{ item.address.addressFull }}
            </Btn>
          </div>
        </div>

        <div v-if="deliveryPoint" class="mt-6">
          <div class="mb-2 text-base font-medium">
            {{ deliveryPoint.name }}
          </div>
          <div class="mb-1.5 text-sm">
            {{ deliveryPoint.addressFull }}
          </div>
          <div class="text-secondary text-xs">
            {{ deliveryPoint.workTime }}
          </div>
        </div>
      </div>

      <div class="grow" />

      <div v-if="errorMessage" class="text-danger mt-6">
        {{ errorMessage }}
      </div>

      <Btn
        lg
        primary
        class="mt-6 w-full"
        :disabled="submitLoading"
        @click="submit"
      >
        {{ submitText }}
      </Btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import {
  loadYmap,
  yandexMap as YandexMap,
  ymapMarker as YmapMarker,
  // @ts-ignore
} from 'vue-yandex-maps'
import DeliveryAddressMarkerSvg from '@valta/assets/images/delivery-address-marker.svg'
import {
  DeliveryTypeEnum,
  OrderDeliveryPointsTypeEnum,
  type IOrderDeliveryZonesCollection,
  type IOrderDeliveryZonesCollectionFeature,
  type IOrderDeliveryAddressSuccessEmit,
} from '@/types'
import type {
  DeliveryAddress,
  DeliveryAddressRequest,
  CdekCity,
  DeliverPoints,
  DeliveryCityArea,
  Status,
  FilialRegion,
} from '@/openapi_fetch'
import { StatusFromJSON } from '@/openapi_fetch'
import { DialogConfirm } from '#components'

enum FormDeliveryTypeEnum {
  COURIER,
  PICK_UP,
}

type DeliveryAddressOrRequest = DeliveryAddress | DeliveryAddressRequest

type AddressInputPublicInstance = {
  focus: () => void
  blur: () => void
  reset: () => void
}

const DEFAULT_ZOOM = 11
const STREET_ZOOM = 14
const HOUSE_ZOOM = 16
const MSK_COORDS = [55.755864, 37.617698]

const props = withDefaults(
  defineProps<{
    submitText?: string
    initDeliveryAddress?: DeliveryAddressOrRequest | null
    initDeliveryType?: DeliveryTypeEnum
    initPickupAddress?: DeliverPoints | null
    title?: string
    description?: string
    checkRegion?: boolean
    // для проверки региона (когда checkRegion = true)
    orderAddress?: DeliveryAddress
  }>(),
  {
    submitText: 'Подтвердить',
    title: 'Укажите адрес доставки',
    description: '',
  },
)

const emit = defineEmits(['success'])

const CHECK_REGION_TITLE =
  'Внимание, вы пытаетесь сменить регион, остатки и цены будут пересчитаны'

const nuxtApp = useNuxtApp()
const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smaller('lg')
const config = useRuntimeConfig()
const { n } = useI18n()
const { DeliveryApi, CatalogApi } = useOpenApi()
const profileStore = useProfileStore()

let deliveryMapInstance: any
let pickupMapInstance: any
let deliveryZones: any
const ymapSettings = {
  apiKey: config.public.YANDEX_MAPS_API_KEY,
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1',
  debug: process.env.NODE_ENV === 'development',
}
const ymapControls = ['zoomControl']
const ymapOptions = {
  searchControlProvider: 'yandex#search',
  // должно убирать открыть в яндекс картах
  // https://yandex.ru/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#Map__param-options.suppressMapOpenBlock
  suppressMapOpenBlock: true,
}

const userDetectedCoords = ref<number[]>()
const deliveryAddressModel = ref<DeliveryAddressOrRequest>()
const deliveryAddressInputRef = ref<AddressInputPublicInstance>()
const isAreasLegendsVisible = ref(true)
const showMap = ref(false)
const formDeliveryType = ref(
  props.initDeliveryType === DeliveryTypeEnum.PICK_UP
    ? FormDeliveryTypeEnum.PICK_UP
    : FormDeliveryTypeEnum.COURIER,
)
const formDeliveryTypeItems = [
  {
    type: FormDeliveryTypeEnum.COURIER,
    text: 'Доставка',
  },
  {
    type: FormDeliveryTypeEnum.PICK_UP,
    text: 'Самовывоз',
  },
]
const pickupMapZoom = ref(DEFAULT_ZOOM)
const pickupAddressModel = ref<DeliveryAddressRequest>()
const pickupAddressInputRef = ref<AddressInputPublicInstance>()
const deliveryPoint = ref<CdekCity>()
const deliveryAreas = ref<DeliveryCityArea[]>()
const deliveryZonesCollection = ref<IOrderDeliveryZonesCollection>()
const addressFromMapErrorMessage = ref('')
const deliveryPointsMap = ref<Record<string, CdekCity[]>>({})
const deliveryPointsLoading = ref(false)
const errorMessage = ref('')
const submitLoading = ref(false)
const isIndividualOrder = ref(false)

const { data: regions } = useLazyAsyncData<FilialRegion[]>(
  '/catalog/regions/',
  () => CatalogApi.catalogRegionsList(),
  {
    getCachedData(key) {
      return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
    },
  },
)

const cityCoords = computed(() => userDetectedCoords.value || MSK_COORDS)

const deliveryAddressCoords = computed(() =>
  deliveryAddressModel.value?.lat && deliveryAddressModel.value?.lon
    ? [deliveryAddressModel.value.lat, deliveryAddressModel.value.lon]
    : undefined,
)

const pickupAddressCoords = computed(() =>
  pickupAddressModel.value?.lat && pickupAddressModel.value?.lon
    ? [pickupAddressModel.value.lat, pickupAddressModel.value.lon]
    : undefined,
)

const areasProperties = computed(() =>
  deliveryAreas.value?.map((item) => ({
    id: item.id,
    name: item.name,
    color: item.color || undefined,
    price: item.price,
    freeAmount: item.freeAmount,
  })),
)

const { data: deliveryAddressRetrieve } = useLazyAsyncData(
  'delivery-address-retrieve',
  () => DeliveryApi.deliveryDeliveryAddressRetrieve(),
)

const { data: pickupAddressRetrieve } = useLazyAsyncData(
  'pickup-address-retrieve',
  () => DeliveryApi.deliveryPickupAddressRetrieve(),
)

const deliveryAddressList = computed(
  () => deliveryAddressRetrieve.value?.deliveryAddress || [],
)

const pickupAddressList = computed(
  () => pickupAddressRetrieve.value?.pickupAddress || [],
)

const deliveryPoints = computed(() => {
  const items = Object.values(deliveryPointsMap.value).flat()
  const code = deliveryPoint.value?.code
  // добавляется выбранный элемент для установки центра при инициализации
  if (code && !items.some((item) => item.code === code)) {
    return [...items, deliveryPoint.value!]
  }
  return items
})

watch(pickupAddressModel, (val, oldVal) => {
  if (val && val.region && val.region !== oldVal?.region) {
    fetchDeliveryPoints(val.region)
  }
})

watch(deliveryAddressModel, (val, oldVal) => {
  if (
    val &&
    (val.region !== oldVal?.region ||
      val.beltwayHit !== oldVal?.beltwayHit ||
      val.beltwayDistance !== oldVal?.beltwayDistance)
  ) {
    clearDeliveryZones()
    getDeliveryAreas(val)
  }

  // если меняется координаты при смене адреса доставки
  // устанавливается сентр карты, слушатель изменения области видимости, чтобы сбросить deliveryAddressModel
  const coords = val?.lat && val?.lon ? [val.lat, val.lon] : undefined
  if (deliveryMapInstance) {
    if (coords) {
      deliveryMapInstance.setCenter(coords, HOUSE_ZOOM)

      deliveryMapInstance.events.add('boundschange', onDeliveryMapBoundschange)

      const polygon = deliveryZones?.searchContaining(coords).get(0)

      if (polygon) {
        deliveryZones?.setOptions('fillOpacity', 0.3)
        polygon.options.set('fillOpacity', 0.6)
      } else {
        deliveryZones?.setOptions('fillOpacity', 0.3)
      }
    } else {
      deliveryMapInstance.events.remove(
        'boundschange',
        onDeliveryMapBoundschange,
      )
    }
  } else {
    throw new Error(
      '[delivery address] при установке адреса не имеется инстанc карты',
    )
  }
})

watch(formDeliveryType, async (val, oldVal) => {
  if (
    val === oldVal ||
    val !== FormDeliveryTypeEnum.COURIER ||
    props.initDeliveryAddress ||
    !pickupAddressCoords.value
  )
    return
  const coords = pickupAddressCoords.value.map(parseFloat)
  const address = coords
    ? await getAddressFromCoords(coords, 'locality')
    : undefined
  if (address) {
    deliveryAddressModel.value = address
  }
})

watch(deliveryPoint, checkDeliveryPoint)

onMounted(async () => {
  if (!props.initDeliveryAddress && !props.initPickupAddress) {
    detectGeolocation()
  }

  if (props.initPickupAddress) {
    deliveryPoint.value = pickupAddressToDeliveryPoint(props.initPickupAddress)
  }

  await loadYmap(ymapSettings)

  showMap.value = true

  // анимация отображения подсказок в мобильном
  // TODO зоны доставки запрашиваются после установки адреса
  isAreasLegendsVisible.value = true
  setTimeout(() => {
    isAreasLegendsVisible.value = false
  }, 1500)
})

onBeforeUnmount(() => {
  destroyMap(deliveryMapInstance)
  destroyMap(pickupMapInstance)
  deliveryZones = undefined
})

function showErrorMessage(error: any) {
  const statusCode = error.response.status
  const apiError = useApiError(error, StatusFromJSON) as Status
  errorMessage.value =
    statusCode === 500 ||
    (statusCode === 503 &&
      apiError.message === 'Ошибка при обращении в API CDEK')
      ? 'Доставка на выбранный Вами адрес временно недоступна, для дополнительной информации обратитесь к Персональному помощнику.'
      : 'Упс, что-то пошло не так, пожалуйста, попробуйте позже.'
}

async function fetchDeliveryPoints(regionId: string) {
  try {
    // точки ПВЗ кэшируются в контексте этого компонента
    if (deliveryPointsMap.value[regionId]) {
      return
    }
    deliveryPointsLoading.value = true
    const result = await DeliveryApi.deliveryDeliverypointsCreate({
      deliveryPointsCreateRequest: {
        regionId,
        typePoint: OrderDeliveryPointsTypeEnum.ALL,
      },
    })
    deliveryPointsMap.value[regionId] = result
  } catch (error) {
    showErrorMessage(error)
    throw error
  } finally {
    deliveryPointsLoading.value = false
  }
}

async function updateDeliveryAddressModel(val: DeliveryAddressOrRequest) {
  // при изменении области видимости карты в onDeliveryMapBoundschange сбрасывается deliveryAddressModel
  // поэтому когда устанавливается новое значение или координаты не равны
  // то удаляется слушатель и будет установлен в вотчере deliveryAddressModel
  deliveryMapInstance?.events.remove('boundschange', onDeliveryMapBoundschange)

  try {
    const geocodeData = await reverseGeocode(val.address)
    if (geocodeData && geocodeData.coords[0] && geocodeData.coords[1]) {
      deliveryAddressModel.value = {
        ...val,
        lat: String(geocodeData.coords[0]),
        lon: String(geocodeData.coords[1]),
      }

      const address = await getAddressFromCoords(geocodeData.coords)
      const deliveryType = getAddressDeliveryType(address)

      isIndividualOrder.value = deliveryType === DeliveryTypeEnum.COURIER_CDEK
    } else {
      throw new Error(
        '[delivery address] не удалось определить координаты адреса в геокодере',
      )
    }
  } catch (error) {
    deliveryAddressModel.value = val
  }
}

async function setDeliveryAddressFromMap() {
  const centerCoords = deliveryMapInstance?.getCenter()
  if (centerCoords) {
    let address: DeliveryAddressRequest | undefined
    const dadataReverseGeocode =
      await DeliveryApi.deliveryGeolocateSuggestionsAddressCreate({
        geoSuggestionsAddressRequestRequest: {
          lat: centerCoords[0],
          lon: centerCoords[1],
        },
      })
    if (dadataReverseGeocode.length) {
      const resultItems = await DeliveryApi.deliverySuggestionsAddressList({
        query: dadataReverseGeocode[0].value,
        // @ts-ignore параметры не добавлены в апи
        count: 1,
      })
      // @ts-ignore у Dadata и DeliveryAddressRequest типы beltwayDistance отличаются
      address = resultItems[0]
    } else {
      address = await getAddressFromCoords(centerCoords)
    }
    if (address) {
      // см. комментарий в updateDeliveryAddressModel
      deliveryMapInstance?.events.remove(
        'boundschange',
        onDeliveryMapBoundschange,
      )
      deliveryAddressModel.value = address
    } else {
      addressFromMapErrorMessage.value = 'Не удалось определить адрес'
    }
  }
}

async function updatePickupAddressModel(val: DeliveryAddressRequest) {
  // сбрасывается выбранная точка, когда происходит ввод в поле поиска
  deliveryPoint.value = undefined
  try {
    const geocodeData = await reverseGeocode(val.address)
    if (geocodeData && geocodeData.coords[0] && geocodeData.coords[1]) {
      pickupAddressModel.value = {
        ...val,
        lat: String(geocodeData.coords[0]),
        lon: String(geocodeData.coords[1]),
      }
    } else {
      throw new Error(
        '[delivery address] не удалось определить координаты адреса в геокодере',
      )
    }
  } catch (error) {
    pickupAddressModel.value = val
  }

  // установка центра и масштаба карты только при вводе в инпуте
  if (
    pickupAddressModel.value &&
    pickupAddressModel.value.lat &&
    pickupAddressModel.value.lon
  ) {
    const zoom =
      pickupAddressModel.value.house || pickupAddressModel.value.stead
        ? HOUSE_ZOOM
        : pickupAddressModel.value.street || pickupAddressModel.value.settlement
          ? STREET_ZOOM
          : DEFAULT_ZOOM

    pickupMapInstance?.setCenter(
      [pickupAddressModel.value.lat, pickupAddressModel.value.lon],
      zoom,
    )

    openClosestBalloon(val)
  }
}

function openClosestBalloon(val?: DeliveryAddressRequest) {
  if (pickupMapInstance && val?.lat && val.lon) {
    const coords = [+val.lat, +val.lon]

    if (val.street || val.settlement || val.house || val.stead) {
      pickupMapInstance.setCenter(coords, HOUSE_ZOOM)
    }
    pickupMapInstance.balloon.close()

    // если координаты адреса пересекают в радиусе 10м с адресом ПВЗ,
    // открывается балун первого найденного
    const ymaps = (window as any).ymaps
    let geoQuery: any

    pickupMapInstance.geoObjects.each((collection: any) => {
      if (collection.getGeoObjects) {
        const objects = collection.getGeoObjects()
        // исключение коллекции с текущим выбранным ПВЗ
        if (
          !(
            objects &&
            objects.length === 1 &&
            objects[0].properties.get('markerId') === deliveryPoint.value?.code
          )
        ) {
          geoQuery = ymaps.geoQuery(objects)
        }
      }
    })

    // временный объект окружности в центре карты с радиусом 10м и с прозрачностью 0, для получения пересечения
    const pickupSearchIntersectCircle = new ymaps.Circle(
      [coords, 10],
      undefined,
      {
        fillColor: '#000000',
        fillOpacity: 0,
        strokeColor: '#000000',
        strokeOpacity: 0,
        strokeWidth: 0,
      },
    )
    // окружность добавляется в карту, после всех манипуляций удаляется с карты
    pickupMapInstance.geoObjects.add(pickupSearchIntersectCircle)

    const result = geoQuery?.searchIntersect(pickupSearchIntersectCircle)
    if (result?.getLength()) {
      let resultMarker: any
      for (let i = 0; i < result.getLength(); i++) {
        const marker = result.get(i)
        if (marker.properties.get('markerId') !== deliveryPoint.value?.code) {
          resultMarker = marker
          break
        }
      }
      resultMarker?.balloon.open()
    }

    pickupMapInstance.geoObjects.remove(pickupSearchIntersectCircle)
  }
}

async function setPickupAddressFromCoords(centerMap = false) {
  let coords: number[] | null = null
  let isLocalityKind = false
  if (deliveryPoint.value?.latitude && deliveryPoint.value?.longitude) {
    coords = [deliveryPoint.value.latitude, deliveryPoint.value.longitude]
  } else if (
    props.initPickupAddress?.latitude &&
    props.initPickupAddress?.longitude
  ) {
    coords = [
      props.initPickupAddress.latitude,
      props.initPickupAddress.longitude,
    ]
  } else if (deliveryAddressCoords.value) {
    coords = deliveryAddressCoords.value.map(parseFloat)
    isLocalityKind = true
  } else if (props.initDeliveryAddress?.lat && props.initDeliveryAddress?.lon) {
    coords = [+props.initDeliveryAddress.lat, +props.initDeliveryAddress.lon]
    isLocalityKind = true
  } else {
    coords = cityCoords.value
    isLocalityKind = true
  }
  const address = coords
    ? await getAddressFromCoords(coords, isLocalityKind ? 'locality' : 'house')
    : undefined
  if (address) {
    pickupAddressModel.value = address

    if (centerMap) {
      pickupMapInstance?.setCenter([address.lat, address.lon])
    }
  }
}

async function setDeliveryPointFromPickupAddress(pickupAddress: DeliverPoints) {
  const found = deliveryPoints.value?.find(
    (item) =>
      item.addressFull === pickupAddress.addressFull ||
      (item.latitude === pickupAddress.latitude &&
        item.longitude === pickupAddress.longitude),
  )
  let coords: number[] | undefined
  if (found) {
    deliveryPoint.value = found
    if (found.latitude && found.longitude) {
      coords = [found.latitude, found.longitude]
    }
  } else {
    deliveryPoint.value = pickupAddressToDeliveryPoint(pickupAddress)
    if (pickupAddress.latitude && pickupAddress.longitude) {
      coords = [pickupAddress.latitude, pickupAddress.longitude]
    }
  }
  try {
    if (coords) {
      const address = await getAddressFromCoords(coords)
      if (address) {
        pickupAddressModel.value = address
      } else if (pickupAddressModel.value) {
        pickupAddressModel.value = {
          ...pickupAddressModel.value,
          address:
            pickupAddress.addressFull || pickupAddressModel.value.address,
          lat: String(coords[0]),
          lon: String(coords[1]),
          region: pickupAddress.regionCode || pickupAddressModel.value.region,
        }
      }
    } else {
      throw new Error('не установлены координаты')
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(
      `[delivery address] не удалось установить адрес для получения точек пвз, ${error}`,
    )
  }
}

function pickupAddressToDeliveryPoint(pickupAddress: DeliverPoints) {
  return {
    code: pickupAddress.code || undefined,
    cityCode: pickupAddress.cityCode || undefined,
    name: pickupAddress.name || undefined,
    workTime: pickupAddress.workTime || undefined,
    phones: pickupAddress.phones ? [pickupAddress.phones] : undefined,
    type: pickupAddress.typePints || undefined,
    longitude: pickupAddress.longitude || undefined,
    latitude: pickupAddress.latitude || undefined,
    addressFull: pickupAddress.addressFull || undefined,
  }
}

async function getAddressFromCoords(
  coords: number[],
  kind: 'house' | 'locality' = 'house',
) {
  const geocodeData = await reverseGeocode(coords, { kind })

  let result: DeliveryAddressRequest | undefined

  const resultItems = await DeliveryApi.deliverySuggestionsAddressList({
    query: geocodeData?.addressSearchQuery,
    // @ts-ignore параметры не добавлены в апи
    count: 1,
  })

  if (kind === 'locality' || resultItems[0]?.house || resultItems[0]?.stead) {
    // @ts-ignore у Dadata и DeliveryAddressRequest типы beltwayDistance отличаются
    result =
      geocodeData && geocodeData.coords[0] && geocodeData.coords[1]
        ? {
            ...resultItems[0],
            lat: String(geocodeData.coords[0]),
            lon: String(geocodeData.coords[1]),
          }
        : resultItems[0]
  }
  return result
}

function detectGeolocation() {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        const coords = [pos.coords.latitude, pos.coords.longitude]
        userDetectedCoords.value = coords
        const address = await getAddressFromCoords(coords)
        if (address) {
          deliveryAddressModel.value = address

          const deliveryType = getAddressDeliveryType(address)

          isIndividualOrder.value =
            deliveryType === DeliveryTypeEnum.COURIER_CDEK
        }
      },
      (error) => {
        if (error.code !== GeolocationPositionError.PERMISSION_DENIED) {
          // eslint-disable-next-line no-console
          console.log(
            `[delivery address] Ошибка определения координат пользователя ${error.code}, ${error.message}`,
          )
        }
      },
    )
  } else {
    // eslint-disable-next-line no-console
    console.log('[delivery address] Geolocation не поддерживается браузером')
  }
}

async function reverseGeocode(
  request: string | number[],
  options?: Record<string, any>,
) {
  try {
    await loadYmap(ymapSettings)
    const ymaps = (window as any).ymaps
    // https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/geocode.xml
    const res = await ymaps.geocode(
      request,
      Object.assign({ results: 1 }, options),
    )
    const geoObject = res.geoObjects.get(0)
    const address = geoObject?.getAddressLine()
    const coords = geoObject?.geometry.getCoordinates()
    const administrativeArea = geoObject?.getAdministrativeAreas()?.[0]
    const localities = geoObject?.getLocalities()?.join(' ')
    const thoroughfare = geoObject?.getThoroughfare()
    const premiseNumber = geoObject?.getPremiseNumber()
    const addressSearchQuery = [
      administrativeArea,
      localities,
      thoroughfare,
      premiseNumber,
    ]
      .filter(Boolean)
      .join(' ')
    if (address && coords) {
      return {
        address,
        coords,
        addressSearchQuery,
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('[delivery address] ошибка при геокодера', error)
  }
}

function destroyMap(instance: any) {
  if (instance) {
    if (instance.geoObjects) {
      instance.geoObjects.removeAll()
    }
    instance.destroy()
    instance = undefined
  }
}

async function onDeliveryMapInit(e: unknown) {
  deliveryMapInstance = e

  deliveryMapInstance.events.add('click', onDeliveryMapClick)

  if (props.initDeliveryAddress) {
    deliveryAddressModel.value = props.initDeliveryAddress

    if (props.initDeliveryAddress.lat && props.initDeliveryAddress.lon) {
      const address = await getAddressFromCoords([
        Number(props.initDeliveryAddress.lat),
        Number(props.initDeliveryAddress.lon),
      ])
      const deliveryType = getAddressDeliveryType(address)

      isIndividualOrder.value = deliveryType === DeliveryTypeEnum.COURIER_CDEK
    }
  } else if (!deliveryAddressModel.value) {
    if (
      !userDetectedCoords.value &&
      props.initPickupAddress?.latitude &&
      props.initPickupAddress?.longitude
    ) {
      userDetectedCoords.value = [
        props.initPickupAddress.latitude,
        props.initPickupAddress.longitude,
      ]
    }
  }

  const ymaps = (window as any).ymaps
  // Элемент управления с настройками геолокации на карте
  const geolocationControl = new ymaps.control.GeolocationControl({
    options: {
      noPlacemark: true,
      float: 'none',
      position: {
        right: '12px',
        bottom: '32px',
      },
    },
  })
  geolocationControl.events.add('locationchange', async (event: any) => {
    const position = event.get('position')
    if (position) {
      userDetectedCoords.value = position
      const address = await getAddressFromCoords(position)
      if (address) {
        updateDeliveryAddressModel(address)
      }
    } else {
      deliveryMapInstance.setCenter(position, HOUSE_ZOOM)
    }
  })
  deliveryMapInstance.controls.add(geolocationControl)
}

async function onDeliveryMapBoundschange(e: any) {
  const oldCenter = e.get('oldCenter')
  const newCenter = e.get('newCenter')
  if (JSON.stringify(oldCenter) !== JSON.stringify(newCenter)) {
    deliveryAddressModel.value = undefined
  }
}

function onDeliveryMapClick(e: any) {
  const coords = e.get('coords')
  deliveryMapInstance?.setCenter(coords)
}

async function onPickupMapInit(e: unknown) {
  pickupMapInstance = e

  await setPickupAddressFromCoords(true)
  checkDeliveryPoint(deliveryPoint.value)
}

function checkDeliveryPoint(val?: CdekCity) {
  const code = val?.code
  if (code && deliveryPoints.value.some((item) => item.code === code)) {
    pickupMapInstance?.setCenter([val.latitude, val.longitude])
  }
}

async function onPickupMapBoundschange(e: any) {
  const oldCenter = e.get('oldCenter')
  const newCenter = e.get('newCenter')
  if (JSON.stringify(oldCenter) !== JSON.stringify(newCenter)) {
    const oldGeocodeData = await reverseGeocode(oldCenter, { kind: 'locality' })
    const newGeocodeData = await reverseGeocode(newCenter, { kind: 'locality' })
    if (oldGeocodeData?.address === newGeocodeData?.address) {
      return
    }
    const address = await getAddressFromCoords(newCenter, 'locality')
    if (address && address.region !== pickupAddressModel.value?.region) {
      pickupAddressModel.value = address
    }
  }
}

async function getDeliveryAreas(address: DeliveryAddressOrRequest) {
  if (!address.region) {
    return
  }

  const region = address.region

  try {
    const response = await DeliveryApi.deliveryListAreaList({
      region: Number(region),
      beltwayHit: address.beltwayHit || undefined,
      beltwayDistance: address.beltwayDistance || undefined,
    })

    deliveryAreas.value = response

    const features = response
      .map((item) => {
        if (item.coordinates === '') {
          return undefined
        }

        let coordinates = [] as number[][][]
        try {
          coordinates = JSON.parse(`[${item.coordinates}]`)
          // координаты приходят в geojson долгота, широта
          coordinates = coordinates.map((item) =>
            item.map((item) => [item[1], item[0]]),
          )
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(
            '[delivery address] ошибка парсинг координат зон доставки /delivery/list-area/',
            item.id,
            error,
          )
          return undefined
        }

        return {
          type: 'Feature',
          id: item.id,
          geometry: {
            type: 'Polygon',
            coordinates,
          },
          properties: {
            area: item.name,
            fillColor: item.color,
            fillOpacity: 0.3,
            strokeColor: item.color,
            strokeWidth: 0,
            strokeOpacity: 0,
          },
        }
      })
      .filter(Boolean) as IOrderDeliveryZonesCollectionFeature[]

    if (features.length) {
      deliveryZonesCollection.value = {
        type: 'FeatureCollection',
        metadata: {
          name: 'delivery',
          creator: 'Yandex Map Constructor',
        },
        features,
      }
    } else {
      deliveryZonesCollection.value = undefined
    }

    initDeliveryZones()
  } catch (error) {
    clearDeliveryZones()
    // eslint-disable-next-line no-console
    console.error('[delivery address] ошибка получения зон доставки', error)
  }
}

function initDeliveryZones() {
  const ymaps = (window as any).ymaps

  if (deliveryZonesCollection.value) {
    deliveryZones?.removeFromMap(deliveryMapInstance)

    deliveryZones = ymaps
      .geoQuery(deliveryZonesCollection.value)
      .addToMap(deliveryMapInstance)

    deliveryZones.each((obj: any) => {
      obj.options.set({
        fillColor: obj.properties.get('fillColor'),
        fillOpacity: obj.properties.get('fillOpacity'),
        strokeColor: obj.properties.get('strokeColor'),
        strokeWidth: obj.properties.get('strokeWidth'),
        strokeOpacity: obj.properties.get('strokeOpacity'),
      })
    })
  }
}

function clearDeliveryZones() {
  deliveryMapInstance && deliveryZones?.removeFromMap(deliveryMapInstance)
  deliveryZones = undefined
  deliveryZonesCollection.value = undefined
  deliveryAreas.value = undefined
}

function isAddressContainsArea(address?: DeliveryAddressOrRequest) {
  if (deliveryAreas.value?.length && address?.beltwayHit) {
    return true
  }

  let area
  const _coords =
    address?.lat && address?.lon ? [address.lat, address.lon] : undefined
  if (!_coords) {
    // eslint-disable-next-line no-console
    console.warn(
      '[delivery address] при проверке вхождения адреса в зону доставки у адреса не установлены геокоординаты',
    )
  }
  try {
    const polygon = _coords
      ? deliveryZones?.searchContaining(_coords).get(0)
      : undefined
    area = polygon?.properties.get('area')
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      '[delivery address]  ошибка определения вхождения адреса в зону доставки',
      error,
    )
  }
  return !!area
}

function getAddressDeliveryType(address?: DeliveryAddressRequest) {
  const isContain = isAddressContainsArea(address)

  return isContain
    ? DeliveryTypeEnum.COURIER_VALTA
    : DeliveryTypeEnum.COURIER_CDEK
}

function onBalloonAction(id: string, balloon: any) {
  const selected = deliveryPoints.value?.find((item) => item.code === id)
  if (selected) {
    deliveryPoint.value = selected
    balloon.close()
    setPickupAddressFromCoords()
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      '[delivery address] не удалось установить пункт выдачи заказов',
    )
  }
}

function onBalloonOpen(e: any) {
  const balloon = e.get('target')?.balloon
  const code = balloon?.getData().id
  if (code) {
    document
      .getElementById(code)
      ?.addEventListener('click', () => onBalloonAction(code, balloon))
  } else {
    // eslint-disable-next-line no-console
    console.warn('[delivery address] при открытии балуна не имеется `code`')
  }
}

function onBalloonClose(e: any) {
  const balloon = e.get('target')?.balloon
  const code = balloon?.getData().id
  if (code) {
    document
      .getElementById(code)
      ?.removeEventListener('click', () => onBalloonAction(code, balloon))
  } else {
    // eslint-disable-next-line no-console
    console.warn('[delivery address] при закрытии балуна не имеется код')
  }
}

const onClickMapHandler = async () => {
  const centerCoords = deliveryMapInstance?.getCenter()
  const address = await getAddressFromCoords(centerCoords)
  const deliveryType = getAddressDeliveryType(address)

  isIndividualOrder.value = deliveryType === DeliveryTypeEnum.COURIER_CDEK
}

async function submit() {
  if (formDeliveryType.value === FormDeliveryTypeEnum.COURIER) {
    if (
      !(deliveryAddressModel.value?.house || deliveryAddressModel.value?.stead)
    ) {
      deliveryAddressInputRef.value?.focus()
      return
    }
  } else if (formDeliveryType.value === FormDeliveryTypeEnum.PICK_UP) {
    if (!deliveryPoint.value) {
      pickupAddressInputRef.value?.focus()
      return
    }
  }

  const data: IOrderDeliveryAddressSuccessEmit = {
    deliveryType:
      formDeliveryType.value === FormDeliveryTypeEnum.PICK_UP
        ? DeliveryTypeEnum.PICK_UP
        : getAddressDeliveryType(deliveryAddressModel.value),
    address:
      formDeliveryType.value === FormDeliveryTypeEnum.COURIER
        ? deliveryAddressModel.value
        : undefined,
    cdekCode:
      formDeliveryType.value === FormDeliveryTypeEnum.PICK_UP
        ? deliveryPoint.value!.code
        : undefined,
    // поля для проверки филиалов региона в чекауте
    deliveryPointRegion: pickupAddressModel.value?.region || undefined,
    deliveryPointAddress: deliveryPoint.value?.addressFull,
  }

  try {
    submitLoading.value = true

    errorMessage.value = ''

    if (props.checkRegion) {
      const isPickup = data.deliveryType === DeliveryTypeEnum.PICK_UP

      let addressRegion = isPickup
        ? data.deliveryPointRegion
        : data.address?.region

      // если не имеется deliveryPointRegion получить регион из адреса ПВЗ
      if (isPickup && !addressRegion) {
        const resultItems = await DeliveryApi.deliverySuggestionsAddressList({
          query: data.deliveryPointAddress,
          // @ts-ignore параметры не добавлены в апи
          count: 1,
        })

        addressRegion = resultItems[0]?.region
      }

      const oldRegionFilial = regions.value?.find(
        (item) => item.region === Number(props.orderAddress?.region),
      )?.filial
      const newRegionFilial = regions.value?.find(
        (item) => item.region === Number(addressRegion),
      )?.filial

      if (newRegionFilial) {
        if (newRegionFilial !== oldRegionFilial) {
          const result: unknown = await openDialog(DialogConfirm as any, {
            title: CHECK_REGION_TITLE,
          })
          if (!result) return
        }
        await profileStore.setDeliveryAddress(data)
        await navigateTo('/cart/')
        // TODO нужно на беке по DeliveryApi.deliverySetDeliveryCreate() пересчитать корзину по ценам и остаткам нового региона
      } else {
        throw new Error(
          '[delivery address] не удалось определить филиал региона у текущего адреса и нового адреса',
        )
      }
    } else {
      await profileStore.setDeliveryAddress(data)
      // Обновление товаров (цен, остатков) через refreshNuxtData('/api/catalog/products') не работает из-за пагинации
      reloadNuxtApp()
    }

    emit('success', data)
  } catch (error) {
    showErrorMessage(error)
    throw error
  } finally {
    submitLoading.value = false
  }
}
</script>
