import type { HookResult } from '@nuxt/schema'
import { ecommerce } from '@/services/ecommerce'

export default defineNuxtPlugin((nuxtApp) => {
  const { hasuraToken } = useAuth()
  const { AuthApi } = useOpenApi()
  const profileStore = useProfileStore()
  const cartStore = useCartStore()

  nuxtApp.hook('apollo:auth', async ({ token }) => {
    token.value = hasuraToken.value
  })

  nuxtApp.hook('auth:onAuth', async (payload) => {
    await useAsyncData('/auth/cas', () =>
      AuthApi.authCasCreate({
        casAuthRequest: {
          access: payload.access,
          refresh: payload.refresh,
        },
      }),
    )

    if (import.meta.client) {
      ecommerce.successAuth('password_less')
    }
  })

  nuxtApp.hook('auth:onLogout', async () => {
    profileStore.clearStore()
    cartStore.clearStore()
    clearNuxtData()
  })

  nuxtApp.hook('auth:baseURL', async (url) => {
    const result = await AuthApi.authCasCheckAuthRetrieve()
    url.value = result.url
  })

  nuxtApp.hook('auth:tokens', async (ticket, tokens) => {
    const { data } = await useAsyncData('/auth/cas/token', () =>
      AuthApi.authCasTokenCreate({
        casTicketTokenRequest: {
          ticketKey: ticket,
        },
      }),
    )
    tokens.value = {
      access: data.value?.access || '',
      refresh: data.value?.refresh || '',
    }
  })

  nuxtApp.hook('auth:refresh', async (payload, tokens) => {
    const response = await AuthApi.authCasTokenRefreshCreate({
      casExtendTokensRequest: {
        access: payload.access,
        refresh: payload.refresh,
      },
    })
    tokens.value = {
      access: response.access,
    }
  })
})

export interface ModuleRuntimeHooks {
  'apollo:auth': (params: { client: string; token: Ref<string | null> }) => void
  'auth:onAuth': (
    payload: { access: string; refresh: string } & { [key: string]: string },
  ) => HookResult
  'auth:onLogout': () => HookResult
  'auth:baseURL': (url: Ref<string>) => HookResult
  'auth:tokens': (
    ticket: string,
    tokens: Ref<{ access: string; refresh: string }>,
  ) => HookResult
  'auth:refresh': (
    payload: { access: string; refresh: string },
    tokens: Ref<{ access: string }>,
  ) => HookResult
}

declare module '#app' {
  interface RuntimeNuxtHooks extends ModuleRuntimeHooks {}
}
