import revive_payload_client_O0YDLGvcjM from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_UmD9Vgue9a from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_N5iGLK75JS from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_iLu8s4Eg01 from "/app/node_modules/.pnpm/nuxt-site-config@2.2.7_@nuxt+devtools@1.0.8_@vue+compiler-core@3.5.12_nuxt@3.9.3_postcss@8.4._g72ifj4nzqshexamty36pdb2sq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_x8NTHJ7giu from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_1R6UgjCeaq from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_2LPVk9M7vJ from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.24.0_typescript@5.3.3_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/monge/.nuxt/components.plugin.mjs";
import prefetch_client_wMGCcUu3w8 from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_oiXxGtkOR1 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.24.0_vue-router@4.4.5_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_KlH8q0f8TS from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.24.0_vue-router@4.4.5_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_MvQ1eByem3 from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.0_rollup@4.24.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_b8IEkl5fyV from "/app/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.6_rollup@4.24.0_typescript@5.3.3_vue@3.4.38/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_HMZ09TN9R7 from "/app/packages/shared/modules/firebase/runtime/plugin.ts";
import chunk_reload_client_YvsTivThFN from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_n8MdMghGw5 from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.24.0_typescript@5.3.3_vite@5.4.9/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_pMkBdpKxwA from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.1.2_@pinia+nuxt@0.4.11_pinia@2.1.6_rollup@4.24.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import auth_ZfWcKdsxoP from "/app/packages/shared/plugins/auth.ts";
import errorHandling_lsLZ6BYEpW from "/app/packages/shared/plugins/errorHandling.ts";
import gtm_client_ebnBchNkh5 from "/app/packages/shared/plugins/gtm.client.ts";
import maska_ZkrgfuXpAa from "/app/packages/shared/plugins/maska.ts";
import pikaday_client_1uXBfWe2k8 from "/app/packages/shared/plugins/pikaday.client.ts";
import sanitizeHtml_tV4htHVjiU from "/app/packages/shared/plugins/sanitizeHtml.ts";
import sentry_client_uLTgB6jkEr from "/app/packages/shared/plugins/sentry.client.ts";
export default [
  revive_payload_client_O0YDLGvcjM,
  unhead_UmD9Vgue9a,
  router_N5iGLK75JS,
  _0_siteConfig_iLu8s4Eg01,
  payload_client_x8NTHJ7giu,
  check_outdated_build_client_1R6UgjCeaq,
  plugin_vue3_2LPVk9M7vJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wMGCcUu3w8,
  composition_oiXxGtkOR1,
  i18n_KlH8q0f8TS,
  plugin_MvQ1eByem3,
  plugin_b8IEkl5fyV,
  plugin_HMZ09TN9R7,
  chunk_reload_client_YvsTivThFN,
  cross_origin_prefetch_client_n8MdMghGw5,
  plugin_pMkBdpKxwA,
  auth_ZfWcKdsxoP,
  errorHandling_lsLZ6BYEpW,
  gtm_client_ebnBchNkh5,
  maska_ZkrgfuXpAa,
  pikaday_client_1uXBfWe2k8,
  sanitizeHtml_tV4htHVjiU,
  sentry_client_uLTgB6jkEr
]