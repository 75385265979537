<template>
  <nav
    class="hidden items-center lg:flex"
    itemscope
    itemtype="https://schema.org/SiteNavigationElement"
  >
    <ul
      class="text-secondary flex space-x-4 text-sm xl:space-x-6"
      data-test="header"
      itemprop="about"
      itemscope
      itemtype="https://schema.org/ItemList"
    >
      <li
        v-if="!hiddenAddress"
        class="text-primary flex w-full cursor-pointer items-center text-xs transition lg:w-[120px]"
        :class="{ 'xl:!hidden': hiddenAddressXl }"
        data-test="header_adress"
        @click="emit('showDialog')"
      >
        <Icon
          name="compass-alt"
          class="text-primary mr-2 h-[18px] w-[18px] shrink-0"
        />

        <span class="truncate">
          <span
            v-if="addressText"
            class="font-semibold"
            :title="addressFullText"
            >{{ addressText }}</span
          >

          <span v-else>Укажите адрес доставки</span>
        </span>

        <Icon name="expand-down" class="ml-1 h-[14px] w-[14px] shrink-0" />
      </li>

      <li
        v-for="(item, i) in items?.filter((v) => !v?.mobileOnly)"
        :key="i"
        class="hover:text-primary text-blue-gray-700 transition-colors"
        :class="itemClass"
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ItemList"
      >
        <a
          v-if="item.auth"
          :href="item.to"
          :class="item.class"
          class=""
          itemprop="url"
          @click.prevent="emit('goToAuthBefore', item.to)"
        >
          {{ item.text }}
        </a>

        <NuxtLink
          v-else
          :to="item.to"
          :class="item.class"
          class=""
          :data-test="item.dataTest"
          itemprop="url"
        >
          {{ item.text }}
          <Icon v-if="item.external" name="out" class="ml-1 inline" />
        </NuxtLink>

        <meta itemprop="name" :content="item.text" />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
defineProps<{
  hiddenAddress?: boolean
  hiddenAddressXl?: boolean
  addressText?: string
  addressFullText?: string
  items?: [
    {
      auth?: boolean
      mobileOnly?: boolean
      to: string
      text: string
      class?: string
      dataTest?: string
      external?: boolean
    },
  ]
  itemClass?: string
}>()

const emit = defineEmits<{
  (e: 'showDialog'): void
  (e: 'goToAuthBefore', url: string): void
}>()
</script>
