/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeoLocateAddressData
 */
export interface GeoLocateAddressData {
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    countryIsoCode: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    federalDistrict?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    regionFiasId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    regionKladrId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    regionIsoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    regionWithType: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    regionType: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    regionTypeFull?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    areaFiasId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    areaKladrId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    areaWithType?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    areaType?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    areaTypeFull?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    area?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    cityFiasId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    cityKladrId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    cityWithType?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    cityType?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    cityTypeFull?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    streetFiasId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    streetKladrId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    streetWithType?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    streetType?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    streetTypeFull?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    houseFiasId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    houseKladrId?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    houseType?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    houseTypeFull?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    house?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    blockType?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    block?: string;
    /**
     * 
     * @type {number}
     * @memberof GeoLocateAddressData
     */
    geoLat?: number;
    /**
     * 
     * @type {number}
     * @memberof GeoLocateAddressData
     */
    geoLon?: number;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    beltwayHit?: string;
    /**
     * 
     * @type {string}
     * @memberof GeoLocateAddressData
     */
    beltwayDistance?: string;
}

/**
 * Check if a given object implements the GeoLocateAddressData interface.
 */
export function instanceOfGeoLocateAddressData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "countryIsoCode" in value;
    isInstance = isInstance && "regionWithType" in value;
    isInstance = isInstance && "regionType" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "city" in value;

    return isInstance;
}

export function GeoLocateAddressDataFromJSON(json: any): GeoLocateAddressData {
    return GeoLocateAddressDataFromJSONTyped(json, false);
}

export function GeoLocateAddressDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoLocateAddressData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'country': json['country'],
        'countryIsoCode': json['country_iso_code'],
        'federalDistrict': !exists(json, 'federal_district') ? undefined : json['federal_district'],
        'regionFiasId': !exists(json, 'region_fias_id') ? undefined : json['region_fias_id'],
        'regionKladrId': !exists(json, 'region_kladr_id') ? undefined : json['region_kladr_id'],
        'regionIsoCode': !exists(json, 'region_iso_code') ? undefined : json['region_iso_code'],
        'regionWithType': json['region_with_type'],
        'regionType': json['region_type'],
        'regionTypeFull': !exists(json, 'region_type_full') ? undefined : json['region_type_full'],
        'region': json['region'],
        'areaFiasId': !exists(json, 'area_fias_id') ? undefined : json['area_fias_id'],
        'areaKladrId': !exists(json, 'area_kladr_id') ? undefined : json['area_kladr_id'],
        'areaWithType': !exists(json, 'area_with_type') ? undefined : json['area_with_type'],
        'areaType': !exists(json, 'area_type') ? undefined : json['area_type'],
        'areaTypeFull': !exists(json, 'area_type_full') ? undefined : json['area_type_full'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'cityFiasId': !exists(json, 'city_fias_id') ? undefined : json['city_fias_id'],
        'cityKladrId': !exists(json, 'city_kladr_id') ? undefined : json['city_kladr_id'],
        'cityWithType': !exists(json, 'city_with_type') ? undefined : json['city_with_type'],
        'cityType': !exists(json, 'city_type') ? undefined : json['city_type'],
        'cityTypeFull': !exists(json, 'city_type_full') ? undefined : json['city_type_full'],
        'city': json['city'],
        'streetFiasId': !exists(json, 'street_fias_id') ? undefined : json['street_fias_id'],
        'streetKladrId': !exists(json, 'street_kladr_id') ? undefined : json['street_kladr_id'],
        'streetWithType': !exists(json, 'street_with_type') ? undefined : json['street_with_type'],
        'streetType': !exists(json, 'street_type') ? undefined : json['street_type'],
        'streetTypeFull': !exists(json, 'street_type_full') ? undefined : json['street_type_full'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'houseFiasId': !exists(json, 'house_fias_id') ? undefined : json['house_fias_id'],
        'houseKladrId': !exists(json, 'house_kladr_id') ? undefined : json['house_kladr_id'],
        'houseType': !exists(json, 'house_type') ? undefined : json['house_type'],
        'houseTypeFull': !exists(json, 'house_type_full') ? undefined : json['house_type_full'],
        'house': !exists(json, 'house') ? undefined : json['house'],
        'blockType': !exists(json, 'block_type') ? undefined : json['block_type'],
        'block': !exists(json, 'block') ? undefined : json['block'],
        'geoLat': !exists(json, 'geo_lat') ? undefined : json['geo_lat'],
        'geoLon': !exists(json, 'geo_lon') ? undefined : json['geo_lon'],
        'beltwayHit': !exists(json, 'beltway_hit') ? undefined : json['beltway_hit'],
        'beltwayDistance': !exists(json, 'beltway_distance') ? undefined : json['beltway_distance'],
    };
}

export function GeoLocateAddressDataToJSON(value?: GeoLocateAddressData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postal_code': value.postalCode,
        'country': value.country,
        'country_iso_code': value.countryIsoCode,
        'federal_district': value.federalDistrict,
        'region_fias_id': value.regionFiasId,
        'region_kladr_id': value.regionKladrId,
        'region_iso_code': value.regionIsoCode,
        'region_with_type': value.regionWithType,
        'region_type': value.regionType,
        'region_type_full': value.regionTypeFull,
        'region': value.region,
        'area_fias_id': value.areaFiasId,
        'area_kladr_id': value.areaKladrId,
        'area_with_type': value.areaWithType,
        'area_type': value.areaType,
        'area_type_full': value.areaTypeFull,
        'area': value.area,
        'city_fias_id': value.cityFiasId,
        'city_kladr_id': value.cityKladrId,
        'city_with_type': value.cityWithType,
        'city_type': value.cityType,
        'city_type_full': value.cityTypeFull,
        'city': value.city,
        'street_fias_id': value.streetFiasId,
        'street_kladr_id': value.streetKladrId,
        'street_with_type': value.streetWithType,
        'street_type': value.streetType,
        'street_type_full': value.streetTypeFull,
        'street': value.street,
        'house_fias_id': value.houseFiasId,
        'house_kladr_id': value.houseKladrId,
        'house_type': value.houseType,
        'house_type_full': value.houseTypeFull,
        'house': value.house,
        'block_type': value.blockType,
        'block': value.block,
        'geo_lat': value.geoLat,
        'geo_lon': value.geoLon,
        'beltway_hit': value.beltwayHit,
        'beltway_distance': value.beltwayDistance,
    };
}

