/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeoSuggestionsAddressRequestRequest
 */
export interface GeoSuggestionsAddressRequestRequest {
    /**
     * 
     * @type {number}
     * @memberof GeoSuggestionsAddressRequestRequest
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof GeoSuggestionsAddressRequestRequest
     */
    lon: number;
}

/**
 * Check if a given object implements the GeoSuggestionsAddressRequestRequest interface.
 */
export function instanceOfGeoSuggestionsAddressRequestRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lat" in value;
    isInstance = isInstance && "lon" in value;

    return isInstance;
}

export function GeoSuggestionsAddressRequestRequestFromJSON(json: any): GeoSuggestionsAddressRequestRequest {
    return GeoSuggestionsAddressRequestRequestFromJSONTyped(json, false);
}

export function GeoSuggestionsAddressRequestRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoSuggestionsAddressRequestRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lat': json['lat'],
        'lon': json['lon'],
    };
}

export function GeoSuggestionsAddressRequestRequestToJSON(value?: GeoSuggestionsAddressRequestRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lat': value.lat,
        'lon': value.lon,
    };
}

