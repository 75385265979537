<template>
  <div>
    <Dialog
      :model-value="true"
      :title="title"
      @update:model-value="closeDialog(false)"
    >
      <div class="grid gap-4">
        <div v-if="text" v-html="text" />
        <Btn v-if="!hideOk" primary @click="closeDialog()">
          {{ okText }}
        </Btn>
        <Btn
          v-if="!hideCancel"
          secondary
          class="bg-primary-50"
          @click="closeDialog(false)"
        >
          {{ cancelText }}
        </Btn>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    title?: string
    text?: string
    okText?: string
    cancelText?: string
    hideOk?: boolean
    hideCancel?: boolean
  }>(),
  {
    okText: 'Подтвердить',
    cancelText: 'Отменить',
  },
)

const returnValue = () => true

defineExpose({ returnValue })
</script>
