import type { FetchContext, FetchOptions, FetchResponse } from 'ofetch'
import { parseCookies, setCookie } from 'h3'
import { DefultCooikesEnum } from '@/types'
import {
  AccountsApi,
  AnalyticsApi,
  ArticleApi,
  AuthApi,
  BannersApi,
  BrokerApi,
  CatalogApi,
  CompanyApi,
  Configuration,
  DeliveryApi,
  FeedbackApi,
  FilesApi,
  LoyalityApi,
  ManagmentApi,
  NotificationsApi,
  PaymentsApi,
  // PicturesApi,
  VerificationApi,
  ReviewsApi,
  SmsApi,
  SubscribersApi,
  SeoApi,
  TelegramApi,
} from '@/openapi_fetch'
import type { ConfigurationParameters } from '@/openapi_fetch'

export function useOpenApi() {
  const { APP_HOST, SITE_ID } = useRuntimeConfig().public
  const event = useRequestEvent()
  const { accessToken, removeTokens } = useAuth()

  const fetchApi = <DType = any>(
    request: RequestInfo,
    options?: FetchOptions,
  ) =>
    $fetch.raw<DType>(request, {
      ...options,
      headers: {
        'site-id': String(SITE_ID),
      },
      onRequest(context): Promise<void> | void {
        const newHeaders = { ...context.options.headers } as Record<
          string,
          string
        >
        if (process.server) {
          const cookies = parseCookies(event)
          cookies[DefultCooikesEnum.sessionKey] =
            cookies[DefultCooikesEnum.sessionKey] ||
            event.context.params?.sessionid ||
            ''
          newHeaders.Cookie = Object.entries(cookies)
            .map(([k, v]) => `${k}=${v}`)
            .join('; ')
        }
        if (accessToken.value) {
          newHeaders.Authorization = `Bearer ${accessToken.value}`
        }
        context.options.headers = newHeaders
      },
      onResponse(
        context: FetchContext & { response: FetchResponse<ResponseType> },
      ): Promise<void> | void {
        if (process.server) {
          if (context.request.toString().includes('/session')) {
            setCookie(
              event,
              DefultCooikesEnum.sessionKey,
              context.response._data.sessionid,
              { maxAge: context.response._data.max_age - 60 },
            )
            event.context.params = {
              ...event.context.params,
              sessionid: context.response._data.sessionid,
            }
          }
        }
      },
      onResponseError({ response }) {
        if (response.status === 401) {
          removeTokens()
        }
      },
      method: options?.method as
        | 'GET'
        | 'POST'
        | 'PUT'
        | 'DELETE'
        | 'OPTIONS'
        | 'HEAD'
        | 'CONNECT'
        | 'TRACE',
    })

  const openApiConfigurationParams: ConfigurationParameters = {
    credentials: 'include',
    basePath: APP_HOST,
    // @ts-expect-error
    fetchApi,
  }

  const config = new Configuration(openApiConfigurationParams)

  return {
    AccountApi: new AccountsApi(config),
    AnalyticsApi: new AnalyticsApi(config),
    AuthApi: new AuthApi(config),
    BannersApi: new BannersApi(config),
    ArticleApi: new ArticleApi(config),
    BrokerApi: new BrokerApi(config),
    CatalogApi: new CatalogApi(config),
    CompanyApi: new CompanyApi(config),
    DeliveryApi: new DeliveryApi(config),
    FeedbackApi: new FeedbackApi(config),
    FilesApi: new FilesApi(config),
    LoyalityApi: new LoyalityApi(config),
    ManagmentApi: new ManagmentApi(config),
    VerificationApi: new VerificationApi(config),
    // MailApi: new MailApi(config),
    NotificationsApi: new NotificationsApi(config),
    PaymentsApi: new PaymentsApi(config),
    // PicturesApi: new PicturesApi(config),
    ReviewsApi: new ReviewsApi(config),
    SmsApi: new SmsApi(config),
    SubscribersApi: new SubscribersApi(config),
    SeoApi: new SeoApi(config),
    TelegramApi: new TelegramApi(config),
  }
}
